<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="
                  navbarTitel ? navbarTitel : $t('global.addnewcompany')
                "
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable || isAdmin"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="abbrechen"
                  >
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <!--  <button
                    v-if="editable && $CONST('CONFIG').APPTYPE === 'Education'"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="neueAnmeldung"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-ticket"
                      class="mr-2"
                    />
                    {{ $t("global.newregistration") }}
                  </button> -->

                  <button
                    class="btn btn-primary mr-2"
                    @click="neueMail"
                    v-if="
                      berechtigungen.m_korrespondenz.create &&
                      $CONST('CONFIG').APPTYPE != 'DESKA'
                    "
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-envelope-open-text"
                      class="mr-2"
                    /><span>{{ $t("global.sendemail") }}</span>
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="neueKorrespondenz"
                    v-if="
                      berechtigungen.m_korrespondenz.create &&
                      $CONST('CONFIG').APPTYPE != 'DESKA'
                    "
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-memo"
                      class="mr-2"
                    /><span>{{ $t("global.newcorrespondence") }}</span>
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div class="col-xl-12">
              <firmenangaben
                :id="id"
                :firma="firma"
                :roles="roles"
                :editable="editable"
                :auswahlwerte="auswahlwerte"
                :key="personalAngabenKey"
                @rolesChanged="computeRoles"
                @bildUpdated="ladeFirma(id)"
              />
            </div>
            <div class="col-xl-12">
              <ZusatzangabenFirma :firma="firma" :editable="editable" />
            </div>
            <div class="col-xl-12">
              <kommunikationsangaben
                :person="firma"
                :isPerson="false"
                :roles="roles"
                :editable="editable"
                @commChanged="commChanged"
              />
            </div>
          </div>
        </div>
        <!-- ENDE linke Seite -->

        <!-- START rechte Seite -->
        <div
          class="col-xl-6 pr-4"
          :class="{ 'col-xl-12': !showNavbar }"
          v-if="id"
        >
          <div class="row">
            <div class="col-xl-12">
              <standorte
                :editable="editable"
                :standorte="firma.standorte"
                @standortLoeschen="standortLoeschen"
                @standortHinzufuegen="standortHinzufuegen"
              />
            </div>
            <div class="col-xl-12">
              <abteilungen
                :editable="editable"
                :abteilungen="firma.abteilungen"
                @abteilungLoeschen="abteilungLoeschen"
                @abteilungHinzufuegen="abteilungHinzufuegen"
              />
            </div>
          </div>
        </div>
        <!-- ENDE rechte Seite -->

        <!-- HEADER DER REITER -->

        <div v-if="id && $CONST('CONFIG').APPTYPE != 'DESKA'" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.contactinformation") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 14 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 14 },
                    }"
                    >{{ $t("global.relationships") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_finanzen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.bankdetails") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_finanzen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 5 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 5 },
                    }"
                    >{{ $t("global.invoices") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_korrespondenz.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 6 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 6 },
                    }"
                    >{{ $t("global.correspondence") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_korrespondenz.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 7 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 7 },
                    }"
                    >{{ $t("global.email") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_dokumente.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 8 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 8 },
                    }"
                    >{{ $t("global.documents") }}</router-link
                  >
                </li>

                <li
                  v-show="firma && berechtigungen.m_notizen.read"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 11 }"
                    :to="{
                      name: 'firma',
                      params: { id: id, anzeige: 11 },
                    }"
                    >{{ $t("global.notes") }}</router-link
                  >
                </li>
              </ul>
            </div>

            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <kontaktreiter
                    :person="id"
                    :firma="id"
                    :key="kontaktkey"
                    :editable="editable"
                    :standorte="firma.standorte"
                    :shown="anzeige == 0 ? true : false"
                    @added="mailAdresseHinzufuegen"
                    @updatekontakte="kontaktegeaendert"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <bankverbindung-reiter
                    :person="id"
                    :editable="editable"
                    @updatebankverbindung="bankverbindungeaendert"
                    :shown="anzeige == 1 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 14">
                  <beziehung-reiter
                    :gp="id"
                    :defaultGP="firma"
                    :gptyp="'firma'"
                    :editable="editable"
                    :shown="anzeige == 14 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 5">
                  <rechnung-ausgangliste
                    :firma="id"
                    :editable="editable"
                    :shown="anzeige == 5 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 6">
                  <korrespondenzliste
                    :gptyp="'firma'"
                    :firma="id"
                    :editable="editable"
                    :shown="anzeige == 6 ? true : false"
                  />
                </div>

                <div v-show="anzeige === 7">
                  <mailliste
                    :firma="id"
                    :mailadressen="mailadressen"
                    :editable="editable"
                    :shown="anzeige == 7 ? true : false"
                    :key="mailkey"
                    @confirmed="maillisteUpdated"
                  />
                </div>

                <div v-show="anzeige === 8">
                  <dokumentenliste
                    :person="id"
                    :gptyp="'firma'"
                    :dokumentEditable="editable"
                    :shown="anzeige == 8 ? true : false"
                    :key="componentKey"
                    @updateComponent="updateDokumentenliste"
                  />
                </div>

                <div v-show="anzeige === 11">
                  <notizen-liste
                    :firma="id"
                    :editable="editable"
                    :shown="anzeige == 11 ? true : false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <!-- MODALS -->

    <loeschen-modal id="firma-loeschen-modal" @confirmed="firmaLoeschen" />

    <messagebox
      id="dupletten-msg-box"
      ref="duplettenMsgBox"
      :header-text="$t('global.duplicatesfound')"
      ok
      cancel
      repeat
      :repeat-text="$t('global.openduplicate')"
      @ok="speichern(true)"
      @repeat="oeffneDuplette"
    >
      <p>
        {{ $t("global.companyalreadyexists") }}
      </p>
      <div class="row"></div>
    </messagebox>

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Firmenangaben from "@/components/Geschaeftspartner/Firmenangaben.vue";
import Kommunikationsangaben from "@/components/Geschaeftspartner/Kommunikationsangaben.vue";
import Abteilungen from "@/components/Geschaeftspartner/Abteilungen.vue";
import Standorte from "@/components/Geschaeftspartner/Standorte.vue";
import ZusatzangabenFirma from "@/components/Geschaeftspartner/ZusatzangabenFirma.vue";

import Kontaktreiter from "@/pages/Geschaeftspartner/Kontaktreiter";
import BankverbindungReiter from "@/pages/Geschaeftspartner/BankverbindungReiter";
import Dokumentenliste from "@/components/Reiter/Dokumentenliste";

import Korrespondenzliste from "@/components/Reiter/Korrespondenzliste";
import Mailliste from "@/components/Reiter/Mailliste";

import BeziehungReiter from "@/pages/Geschaeftspartner/BeziehungReiter.vue";

import RechnungAusgangliste from "@/components/Reiter/RechnungAusgangliste.vue";
import NotizenListe from "@/components/Reiter/Notizenliste.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import { pdfFileName } from "@/models/Beleg";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

export default {
  name: "Firma",
  components: {
    HeadMenu,
    Navbar,

    Firmenangaben,
    Kommunikationsangaben,
    Abteilungen,
    Standorte,
    ZusatzangabenFirma,

    Kontaktreiter,
    Korrespondenzliste,
    Mailliste,
    BeziehungReiter,
    BankverbindungReiter,
    RechnungAusgangliste,
    NotizenListe,
    Dokumentenliste,
    LoeschenModal,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    rolleProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editable: false,

      firma: {
        nicht_subventioniert: false,
        interesse: [],
        versand: [],
        abteilungen: [],
        standorte: [],
        anrede: { id: "174BF5D05F2", bezeichnung: "Formell" },
        aktivitaetsstatus: {
          id: "1737FA777AA",
          status: 0,
          bezeichnung: "Aktiv",
        },
      },
      kontaktkey: 0,
      mailkey: 0,
      anmeldungkey: 0,
      personalAngabenKey: 0,

      roles: {
        isStudent: false,
        isDozent: false,
        isMitarbeiter: false,
        isInteressent: false,
        isAlumni: false,
        isDiplomarbeiten: false,
        isReferent: false,
        isKursteilnehmer: false,
        isLieferant: false,
        isTraegerschaft: false,
        isTeilnehmerInfoabend: false,
        isArbeitgeber: false,
        isJobCoach: false,
        isPraxisbildner: false,
        isIVBerater: false,
        isNotfallkontakt: false,
        isAnstaltsdirektor: false,
        isNichtZugeordnet: false,
      },

      nummer: null,
      isAdmin: false,

      rolle: this.rolleProp ? [this.rolleProp] : [],

      adressen: [],
      mailadressen: [],
      bankverbindungen: [],

      dupletten: [],
      componentKey: 0,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.firma) {
        return `${this.$t("global.company")} #${
          this.firma.firmennummer || ""
        } ${this.firma.name}`;
      } else {
        return this.$t("global.newcompany");
      }
    },

    rolleObj() {
      if (this.firma.rolle && this.partnerrollen) {
        return this.partnerrollen.find((r) => r.id === this.firma.rolle[0]);
      }

      return null;
    },
    auswahlwerte: {
      get() {
        return this.$store.state.geschaeftspartner;
      },
    },
    partnerrollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    aktivitaetsstati: {
      get() {
        return this.$store.state.geschaeftspartner.aktivitaetsstati;
      },
    },

    pdfFilename() {
      if (!this.selectedBeleg) return "";
      return pdfFileName(this.selectedBeleg);
    },
  },
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.ladeFirma(val);
        } else this.editable = this.berechtigungen.m_geschaeftspartner.create;
      },
      immediate: true,
    },
    /*
    ["person.bild"]: {
      handler: function (val) {
        if (val) {
          this.personalAngabenKey++;
        }
      },
      immediate: true,
    },
    */
  },
  mounted: function () {
    if (this.partnerrollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    maillisteUpdated() {
      this.mailkey++;
      this.anmeldungkey++;
      this.kontaktkey++;
    },
    mailAdresseHinzufuegen(mailadressen) {
      this.mailadressen = mailadressen;
    },
    computeRoles(rollen) {
      this.firma.rolle = rollen;

      if (rollen) {
        let roles = {
          isStudent: false,
          isDozent: false,
          isMitarbeiter: false,
          isInteressent: false,
          isAlumni: false,
          isDiplomarbeiten: false,
          isReferent: false,
          isKursteilnehmer: false,
          isLieferant: false,
          isTraegerschaft: false,
          isTeilnehmerInfoabend: false,
          isArbeitgeber: false,
          isJobCoach: false,
          isPraxisbildner: false,
          isIVBerater: false,
          isNotfallkontakt: false,
          isAnstaltsdirektor: false,
          isNichtZugeordnet: false,
        };

        let SETUP_ROLLEN = this.$CONST("ROLLEN");

        rollen.forEach(function (selectedRole) {
          roles.isStudent =
            SETUP_ROLLEN.STUDENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isStudent;
          roles.isTeilnehmerInfoabend =
            SETUP_ROLLEN.TEILNEHMER_INFOEVENT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isTeilnehmerInfoabend;
          roles.isDozent =
            SETUP_ROLLEN.DOZENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isDozent;
          roles.isMitarbeiter =
            SETUP_ROLLEN.MITARBEITER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isMitarbeiter;
          roles.isInteressent =
            SETUP_ROLLEN.INTERESSENTEN.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isInteressent;
          roles.isAlumni =
            SETUP_ROLLEN.ALUMNIS.findIndex((role) => role == selectedRole) != -1
              ? true
              : roles.isAlumni;
          roles.isKursteilnehmer =
            SETUP_ROLLEN.KURSTEILNEHMER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isKursteilnehmer;
          roles.isDiplomarbeiten =
            SETUP_ROLLEN.DIPLOMARBEIT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isDiplomarbeiten;
          roles.isTraegerschaft =
            SETUP_ROLLEN.TRAEGERSCHAFT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isTraegerschaft;
          roles.isLieferant =
            SETUP_ROLLEN.LIEFERANTEN.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isLieferant;
          roles.isReferent =
            SETUP_ROLLEN.REFERENTEN.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isReferent;
          roles.isArbeitgeber =
            SETUP_ROLLEN.ARBEITGEBER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isArbeitgeber;
          roles.isJobCoach =
            SETUP_ROLLEN.JOBCOACH.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isJobCoach;
          roles.isPraxisbildner =
            SETUP_ROLLEN.PRAXISBILDNER.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isPraxisbildner;
          roles.isIVBerater =
            SETUP_ROLLEN.IVBERATER.findIndex((role) => role == selectedRole) !=
            -1
              ? true
              : roles.isIVBerater;
          roles.isNotfallkontakt =
            SETUP_ROLLEN.NOTFALLKONTAKT.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isNotfallkontakt;
          roles.isAnstaltsdirektor =
            SETUP_ROLLEN.ANSTALTSDIREKTOR.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isAnstaltsdirektor;
          roles.isNichtZugeordnet =
            SETUP_ROLLEN.NICHTZUGEORDNET.findIndex(
              (role) => role == selectedRole
            ) != -1
              ? true
              : roles.isNichtZugeordnet;
        });

        this.roles = roles;
      }
    },

    commChanged(data) {
      this.firma.telefon_m = data.m;
      this.firma.telefon_p = data.p;
      this.firma.telefon_g = data.g;
    },

    getBildUrl() {
      if (this.firma.bild ? this.firma.bild.includes("files") : false) {
        return this.$CONST("CONFIG").BASEURL + this.firma.bild;
      } else {
        return null;
      }
    },

    kontaktegeaendert(data) {
      if (!data) return;
      this.mailadressen = data.mailadressen;
      this.adressen = data.adressen;

      this.flagChanged();
    },

    bankverbindungeaendert(data) {
      if (!data) return;
      this.bankverbindungen = data.bankverbindungen;

      this.flagChanged();
    },

    qualifikationenGeaendert(data) {
      if (!data) return;

      this.qualifikationen = data.qualifikationen;

      this.flagChanged();
    },

    ladeFirma(id) {
      this.loading = true;
      Api.get("geschaeftspartner/firma/", { params: { id: id } }).then(
        (response) => {
          this.initializeFirma(response.data);
        }
      );
    },

    neueMail() {
      let json = {
        firma: [this.firma],
        zeitpunkt: new Intl.DateTimeFormat("de-CH").format(new Date()),
      };

      Api.post("mail/", json, { params: {} }).then((response) => {
        this.$router.push({
          name: "mail",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },
    neueKorrespondenz() {
      let json = {
        versand: {
          erstelltvon: this.angemeldeterMitarbeiter.person,
          datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          betreff: "Neue Korrespondenz",
          unterschrift1: {
            person: { personName: this.$t("global.notlinked") },
          },
          unterschrift2: {
            person: { personName: this.$t("global.notlinked") },
          },
          inhalt: "",
        },
        empfaenger: [this.id],
        firma: [this.id],
        //anmeldung: this.anmeldung,
      };

      Api.post("korrespondenz/", json).then((response) => {
        this.$router.push({
          name: "korrespondenz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },
    abbrechen() {
      this.$router.push({ name: "firmen-liste" });
    },

    neueAnmeldung() {
      this.$router.push({
        name: "anmeldung.firma",
        params: {
          firmaid: this.id,
        },
      });
    },

    initializeFirma(firma) {
      if (firma) {
        if (
          firma.aktivitaetsstatus?.id ==
          this.$CONST("AKTIVITAETSSTATI").ARCHIVIERT
        )
          this.editable = false;

        this.firma = firma;

        this.firma.bildUrl = this.getBildUrl();

        let roles = this.firma.rolle.map((rolle) => {
          return rolle.id;
        });

        this.computeRoles(roles);
      } else {
        console.error("Firma ist null");
      }

      this.editable = this.berechtigungen.m_geschaeftspartner.update;

      this.initChangeWatcher(this.firma);

      this.loading = false;
    },

    speichern(force) {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.firma);

      if (this.mailadressen != null)
        json.mailadressen = this.mailadressen.map((ma) => {
          return {
            id: ma.id,
            bezeichnung: ma.bezeichnung,
            mailtyp: ma.mailtyp.id,
            delete: ma.delete,
            hauptadresse: ma.hauptadresse,
          };
        });

      if (this.adressen != null)
        json.adressen = this.adressen.map((a) => {
          return {
            id: a.id,
            adresse: a.adresse,
            adressverwendungstyp: a.adressverwendungen.map((adv) => adv.id),
            delete: a.delete,
          };
        });

      if (this.bankverbindungen != null)
        json.bankverbindungen = this.bankverbindungen.map((bv) => {
          return {
            id: bv.id,
            kontonummer: bv.kontonummer,
            bankverbindung: bv.bankverbindung,
            bc_nr: bv.bc_nr,
            iban: bv.iban,

            delete: bv.delete,
          };
        });

      if (this.firma.aktivitaetsstatus != null)
        json.aktivitaetsstatus = this.firma.aktivitaetsstatus.id
          ? this.firma.aktivitaetsstatus.id
          : this.firma.aktivitaetsstatus;

      if (this.firma.anrede != null)
        json.anrede = this.firma.anrede.id
          ? this.firma.anrede.id
          : this.firma.anrede;

      if (this.firma.interesse != null)
        json.interesse = this.firma.interesse.map((i) => {
          return i.id ? i.id : i;
        });

      json.versand = this.firma.versand.map((v) => {
        return v.id ? v.id : v;
      });

      /*json.fach = this.person.fach.map((f) => {
        return f.id;
      });*/

      this.dupletten = [];

      if (!this.id) {
        Api.post("geschaeftspartner/firma/", json, { params: { force: force } })
          .then((response) => {
            this.initializeFirma(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.companysuccessfullysaved"),
            });

            this.$router.replace({
              name: "firma",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            switch (e.response.status) {
              case 409:
                this.dupletten = e.response.data.dupletten;
                this.$refs.duplettenMsgBox.openModal();
                break;
              default:
                this.$notify(apiErrorToAlert(e));
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("geschaeftspartner/firma/", json, {
          params: { id: this.firma.id },
        })
          .then((response) => {
            this.initializeFirma(response.data);
            this.kontaktkey++;
            this.adressen = []; // Bewusst leer machen damit neue Adressen nicht dupliziert werden

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.businesssavedsuccess"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneDuplette() {
      if (this.dupletten.length > 0) {
        let id = this.dupletten[0].id;
        let route = this.$router.resolve({
          name: "GeschaeftspartnerMitIdUndAnzeige",
          params: { id: id, anzeige: 0 },
        });
        let win = window.open(route.href, "_blank");
        win.focus();
      }
    },

    updateDokumentenliste() {
      this.componentKey++;
    },

    firmaLoeschen() {
      Api.delete("geschaeftspartner/firma/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "firmen-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "firma-loeschen-modal");
    },
    abteilungLoeschen(abteilung) {
      this.firma.abteilungen = this.firma.abteilungen.filter(
        (element) => element !== abteilung
      );
    },
    abteilungHinzufuegen() {
      this.firma.abteilungen.push({ id: "", bezeichnung: "" });
    },
    standortLoeschen(standort) {
	  this.firma.standorte = this.firma.standorte.filter(
        (element) => element !== standort
      );
    },
    standortHinzufuegen() {
      this.firma.standorte.push({ id: "", bezeichnung: "" });
    },
  },
};
</script>

<style></style>
