import Api from "@/Api";
import KotlinAppApi from "@/KotlinAppApi";

import { debounce } from "@/utils/Debouncer";

import InfiniteLoading from "vue-infinite-loading";
import AppApi from "@/AppApi";

export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      apiCall: null,
      //TemplateBildungsgangliste: null,
      filterId: null,
      anzahlDaten: null,
      anzahlDatenFilter: null,
      page: 0,
      anzahlProPage: 50,
      infiniteId: +new Date(),
      listData: [],
      filterAusgeloest: false,
      filters: {},
      filterValues: {},
      selectedIds: [],
      selectedObjects: [],
      lastAPICall: null, // Sicherheitscheck für Race Condition, ob der erste Call gemacht wurde
      selectAll: null,
      langtextLoopCount: 0,
      gesamtanzahl: null,
    };
  },
  watch: {
    filterCountVals(val) {
      if (
        val[0] >= 1000 ||
        val[1] >= 1000 ||
        val[2] >= 1000 ||
        val[3] >= 1000
      ) {
        if (!document.getElementById("warning-label")) {
          const el = document.getElementById("number-of-records");
          let warningLabel = document.createElement("label");
          warningLabel.textContent = this.filterCountWarningMsg;
          warningLabel.classList.add("validation-warning-input");
          warningLabel.setAttribute("id", "warning-label");
          el.after(warningLabel);
        } else {
          document.getElementById("warning-label").textContent =
            this.filterCountWarningMsg;
        }
      } else if (document.getElementById("warning-label")) {
        let el = document.getElementById("warning-label");
        el.remove();
      }
    },
  },
  mounted() {
    this.$bus.$on("searchWithFilter", () => {
      this.filterSearch();
    });

    this.$bus.$on("resetFilter", () => {
      this.clearFilter();
    });
  },
  computed: {
    filterCountWarningMsg() {
      return this.$t(
        "global.toomanyrecordsperformanceofthesystemwillbeaffected"
      );
    },
    filterCountVals() {
      return [
        this.filters.count,
        this.filterCount,
        this.filters.filterCount,
        this.filters.size,
      ];
    },
    listenfilter: {
      get() {
        return this.$store.state.filter.listenfilter;
      },
      set(val) {
        this.$store.state.filter.listenfilter = val;
      },
    },

    filterCountAndSpecials: function () {
      var pageCountSpecial = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.kategorieProp) {
        pageCountSpecial.veranstaltungstyp = this.kategorieProp;
      } else if (
        this.filterId == "faecher-liste" ||
        this.filterId == "template-faecher-liste"
      ) {
        pageCountSpecial.veranstaltungstyp = this.$CONST(
          "VERANSTALTUNGSTYPEN"
        ).MODULE;
      } else if (
        this.filterId == "klassen-liste" ||
        this.filterId == "template-klassen-liste"
      ) {
        pageCountSpecial.veranstaltungstyp = this.$CONST(
          "VERANSTALTUNGSTYPEN"
        ).STUDIES;
      } else if (this.filterId == "bildungs-liste-kurse") {
        pageCountSpecial.veranstaltungstyp = [
          "174D91487E8",
          "174D91487E0",
          "174D91487D6",
          "174D91487E4",
        ].join(",");
      } else if (this.filterId == "template-kurs-liste") {
        pageCountSpecial.veranstaltungstyp = "174D91487E8";
      }

      return pageCountSpecial;
    },
  },
  methods: {
    setHeaders(prefix, headerTexts) {
      if (this.langtextLoopCount < 15) {
        setTimeout(
          function () {
            if (this.$store.state.langtexts.loaded == true) {
              let count = 0;
              headerTexts.forEach((element) => {
                if (element != "") {
                  this.fields[count].label =
                    this.$store.state.langtexts.texts[this.$i18n.locale][
                      prefix
                    ][element];
                }
                count++;
              });
            } else {
              this.langtextLoopCount++;
              this.setHeaders(prefix, headerTexts);
            }
          }.bind(this),
          500
        );
      }
    },

    setBereiche(prefix, defaultValue, bereichTexte) {
      setTimeout(
        function () {
          if (this.$store.state.langtexts.loaded == true) {
            let count = 0;
            bereichTexte.forEach((element) => {
              if (element != "" && this.bereiche.length >= count + 1) {
                this.bereiche[count].bezeichnung =
                  this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                    element
                  ];
              }
              count++;
            });
            try {
              this.bereich.bezeichnung =
                this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                  defaultValue
                ];
            } catch (err) {
              console.log("Section is empty");
            }
            this.lastValidBereich =
              this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                defaultValue
              ];
          } else {
            this.setBereiche(prefix, defaultValue, bereichTexte);
          }
        }.bind(this),
        500
      );
    },

    setQueryParam(value) {
      return value !== null && value !== "" ? value : null;
    },

    setQueryParamList(value, valueKey = null) {
      if (valueKey !== null) {
        return value ? value.map((item) => item[valueKey]).join(",") : null;
      } else {
        return value ? value.join(",") : null;
      }
    },

    setFilterDate(data) {
      this.$set(this.filters, data.field, data.value);
      this.$set(this.filterValues, data.field, data.value);
    },

    initFilter(id, apiCall, defaultValues) {
      this.filterId = id;
      this.apiCall = apiCall;

      this.filters = this.listenfilter[id] ? this.listenfilter[id] : {};

      if (defaultValues) {
        for (const key in defaultValues) {
          this.filters[key] = defaultValues[key];
        }
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.id
        );

        this.anzahlDaten = this.selectedIds.length;
      } else {
        this.$refs.selectableTable.items.forEach((a) => {
          a.selected = false;
        });
        this.selectedIds = [];
        this.selectedObjects = [];
        this.anzahlDaten = 0;
      }

      this.selectAll = Date.now();

      //this.$forceUpdate();
    },

    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }

      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.id);
      this.anzahlDaten = this.selectedIds.length;
    },

    clearSelectedRows() {
      if (this.$refs.headerCheckbox)
        this.$refs.headerCheckbox.localChecked = false;
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.listData = [];
      self.selectedIds = [];
      self.anzahlDaten = 0;
      self.selectedObjects = [];
      self.infiniteId += 1;
    }, 500),

    infiniteHandler($state, api = "app") {
      if (this.apiCall) {
        this.loading = true;
        let filter = { ...this.filterCountAndSpecials, ...this.filterValues };
        this.lastAPICall = JSON.stringify(filter);

        switch (api) {
          case "app": {
            Api.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }

              this.loading = false;
            });
            break;
          }
          case "kotlin-app-api": {
            KotlinAppApi.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }

              this.loading = false;
            });
            break;
          }
          case "app-api": {
            AppApi.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }

              this.loading = false;
            });
            break;
          }
        }
      } else {
        console.log("API Call nicht gesetzt");
      }
    },

    filterSearch() {
      if (this.filterId) {
        this.filterAusgeloest = true;
        this.filterValues = this.queryParams;
        this.clearSelectedRows();
        this.listenfilter[this.filterId] = this.filters;
        if (this.lastAPICall) this.resetLoading(this);
      } else {
        console.log("Keine Filter ID gesetzt");
      }
    },

    clearFilter() {
      this.filters = [];
      this.filterValues = {};
      if (this.filterAusgeloest) {
        this.filterAusgeloest = false;
        if (this.lastAPICall) this.resetLoading(this);
      }
    },

    setDateTimeQueryParam(dateStr, timeStr) {

        if(dateStr == null || dateStr == "") {
            this.setQueryParam(null);
            return;
        }

        // Das Datum vom Format dd.MM.yyyy in ein Array aufsplitten
        let [day, month, year] = dateStr.split(".");

        // Datum im Format yyyy-MM-dd erstellen
        let formattedDate = `${year}-${month}-${day}`;

        // Überprüfen, ob timeStr nicht null oder leer ist
        if (timeStr && timeStr.trim() !== "") {
            // Datum und Uhrzeit mit einem Leerzeichen zusammenfügen
            return this.setQueryParam(`${formattedDate} ${timeStr}`);
        } else {
            // Nur das Datum zurückgeben, wenn keine Uhrzeit vorhanden ist
            return this.setQueryParam(formattedDate);
        }
    }
  },
};
